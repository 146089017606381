




























































































import { defineComponent } from '@vue/composition-api';

import Hero from '@/components/Hero.vue';

export default defineComponent({
  name: 'ProfileAv',
  // props: {
  // },
  components: {
    Hero
  },
  setup: () => {
    return {};
  }
});
